<!-- 左侧菜单导航栏组件 -->
<template>
  <div class="a-menu-content">
    <el-menu
      :defaultActive="defaultActive"
      text-color="rgba(51,51,51,1);"
      background-color="rgba(255,255,255,1)"
      unique-opened
      :dynamicMenuRoutes="dynamicMenuRoutes"
      :style="{ 'min-height': documentClientHeight  }"
      :collapse="isCollapse"
    >
      <el-submenu v-for="item in (menuChildren || []).filter(e => e.isShow)" :key="item.id + ''" :index="item.id + ''">
        <template slot="title">
          <icon-svg :name="item.icon ? item.icon : 'zh-menu'" class="menu-icon"></icon-svg>
          <span slot="title">{{item.name}}</span>
        </template>
        <el-menu-item-group v-if="item.children && item.children.length > 0">
          <el-menu-item
            v-for="menu in item.children.filter(e => e.isShow)"
            @click="gotoRouteHandle(menu)"
            :index="menu.id + ''"
            :key="menu.id + ''"
          >{{menu.name}}</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'sub-Menu',
  data () {
    return {

    }
  },
  mounted () {
     console.log(this.menuChildren)
  },
  props: {
    menuChildren: {
      type: Array,
      default: []
    },
    documentClientHeight: {
      type: String,
      default: '600'
    },
    isCollapse: {
      type: Boolean,
      default: false
    },
    dynamicMenuRoutes: {
      type: Array,
      required: true
    },
    defaultActive: {
      type: String,
      default: 'home-index'
    }
  },
  methods: {
    gotoRouteHandle (menu) {
      let route = this.dynamicMenuRoutes.filter(item => item.meta.menuId === menu.id)
      if (route.length >= 1) {
        this.$router.push({ name: route[0].name })
      }
    }
  }
}
</script>

<style lang="scss">
.a-menu-content {
  font-size: 12px !important;

  .el-submenu .el-menu-item {
    min-width: 176px;
  }
  .el-menu-item,
  .el-submenu__title {
    height: 50px;
    line-height: 50px;
  }
  .el-menu-item-group__title {
    padding: 0;
  }
  .el-submenu__title {
    font-size: 12px !important;
    padding: 0 14px !important;
  }
  .el-menu-item {
    font-size: 12px !important;
    opacity: 0.7;
    line-height: 40px;
    height: 40px;
  }
  .el-menu {
    background-color: "#1F2E54" !important;
    border-right: none !important;
  }
  .el-aside {
    color: rgba(255, 255, 255, 1);
    opacity: 0.7;
  }
  .el-menu-item:hover {
    outline: 0 !important;
    opacity: 1;
  }
  .el-menu-item.is-active {
    opacity: 1;
  }
  .el-submenu__title:focus,
  .el-submenu__title:hover {
    outline: 0 !important;
    color: #409eff !important;
    background: none !important;
  }
  .el-submenu__icon-arrow {
    margin-top: -6px !important;
    right: 20px;
  }
  /*菜单关闭*/
  .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
    -webkit-transform: rotateZ(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotateZ(-90deg);
  }

  /*菜单展开*/
  .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
    -webkit-transform: rotateZ(0deg);
    -ms-transform: rotate(0deg);
    transform: rotateZ(0deg);
  }
  .menu-icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}
</style>
